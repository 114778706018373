<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div class="uk-inline">
          <button
            class="uk-button uk-button-small-right uk-button-default"
            type="button"
            @click="backCompanyDetail"
          >
            <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
            Back
          </button>
        </div>
        <div class="uk-inline">
          <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
          <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'fullname')" type="text" placeholder="Search Fullname">
        </div>
        <div class="uk-inline">
          <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
          <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'email')" type="text" placeholder="Search Email">
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider">
          <thead>
            <tr>
              <th class="uk-text-center uk-width-auto">Admin Name</th>
              <th class="uk-text-center uk-width-auto">Admin Role</th>
              <th class="uk-text-center uk-width-auto">Email</th>
              <th class="uk-text-center uk-width-auto">Office View</th>
              <th class="uk-text-center uk-width-auto">Role View</th>
              <th class="uk-text-center uk-width-auto">Action</th>
            </tr>
          </thead>
          <tbody v-if="data.docs && data.docs.length>0" class="uk-table-middle uk-text-center uk-table-striped">
            <tr v-for="(data, i) in data.docs" :key="i">
              <td>{{data.user_id.fullname}}</td>
              <td>{{rolesName(data.user_id.roles)}}</td>
              <td :uk-tooltip="data.user_id.email">{{preview(data.user_id.email)}}</td>
              <td :uk-tooltip="data.company_office_ids.map(x => x.name).join(', ')">
                {{ data.company_office_ids.length > 0 ? preview(data.company_office_ids.map(x => x.name).join(', ')) : '-' }}
              </td>
              <td :uk-tooltip="data.company_role_ids.map(x => x.name).join(', ')">
                {{ data.company_role_ids.length > 0 ? preview(data.company_role_ids.map(x => x.name).join(', ')) : `-` }}
              </td>
              <td>
                <button
                  style="width:100px; cursor:pointer; height:30px"
                  class="uk-button-default uk-margin-right"
                  v-if="consist(user_cred.roles, ['admin-bd','qwe123','superadmin-product'])"
                  @click="editForm(data)">
                  Edit
                </button>
              </td>
            </tr>
          </tbody>
          <empty-table v-else :colspan="6" />
        </table>
      </div>
      <pagination
        :total-data="data.totalDocs"
        :limit="meta.limit"
        :change-limit="changeLimit"
        :change-page="changePage"
      />
    </div>

    <div id="formSetting" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Setting OT Report View Form</h2>
        </div>
        <div class="uk-modal-body">

          <div class="uk-margin">
            <label class="uk-form-label">Offices</label>
            <multiselect
                id="offices"
                track-by="_id"
                label="name"
                :close-on-select="false"
                :multiple="true"
                placeholder="Select Offices"
                :limit="2"
                :allow-empty="true"
                group-values="context" 
                group-label="grouplabel"
                :group-select="true"
                :options="office_options"
                v-model="form.company_office_ids"
            ></multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('offices')">{{ errors.first('offices') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Roles</label>
            <multiselect
                id="roles"
                track-by="_id"
                label="name"
                :close-on-select="false"
                :multiple="true"
                placeholder="Select Roles"
                :allow-empty="true"
                group-values="context" 
                group-label="grouplabel"
                :group-select="true"
                :options="role_options"
                :limit="2"
                v-model="form.company_role_ids"
            ></multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('roles')">{{ errors.first('roles') }}</span>
          </div>

        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-primary" type="button" @click="saveForm">Save</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';

export default {
    data() {
        return {
            user_cred: getUserLogin(),
            meta: {
                limit: 50,
                page: 1,
                fullname: null,
                email: null,
                company_id: this.$route.params.company_id.toString(),
            },
            form: {
                company_office_ids: [],
                company_role_ids: [],
                id: null,
            },
            data: {
                docs: [],
                totalDocs: 0
            },
            formIsEdit: false,
            isLoading: false,
            office_options: [],
            role_options: [],
            api_office_options: [],
            api_role_options: [],
            selected_user: [],
            admin_options: [
                {name: 'Employer', value: ['asd123']},
                {name: 'Admin Client', value: ['admin-client']},
                {name: 'Admin BD', value: ['admin-bd']},
                {name: 'Admin Ops', value: ['admin-operation']},
            ],
            isFetching: false,
        };
    },
    components: {
        Pagination,
        EmptyTable
    },
    watch: {
        async meta() {
            await this.setData();
        },
    },
    beforeMount() {
        if (window.UIkit.modal('.setting-modal-box')) window.UIkit.modal('.setting-modal-box').$destroy(true);
        if (window.UIkit.modal('.formSetting')) window.UIkit.modal('.formSetting').$destroy(true);
    },
    async mounted() {
        this.setOptionData();
        await this.setData();
    },
    methods: {
        ...mapActions({
            getCompanySettingOvertimeReportView: 'companySettingV4/getCompanySettingOvertimeReportView',
            updateCompanySettingOvertimeReportView: 'companySettingV4/updateCompanySettingOvertimeReportView',
            getOfficeRoleOptionsCompanySettingOvertimeReportView: 'companySettingV4/getOfficeRoleOptionsCompanySettingOvertimeReportView'
        }),
        async setData() {
            this.isLoading = true;

            const dataResponse = await this.getCompanySettingOvertimeReportView(this.meta);
            this.data = dataResponse.result;
            
            this.isLoading = false;
        },
        async setOptionData() {
            this.isLoading = true;
            
            const optionsResponse = await this.getOfficeRoleOptionsCompanySettingOvertimeReportView({ company_id: this.$route.params.company_id });
            this.api_office_options = optionsResponse.result.offices;
            this.office_options = [
                {
                    grouplabel: 'Select All',
                    context: this.api_office_options
                }
            ];
            this.api_role_options = optionsResponse.result.roles;
            this.role_options = [
                {
                    grouplabel: 'Select All',
                    context: this.api_role_options
                }
            ];
            this.isLoading = false;
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async saveForm() {
            try {
                this.isLoading = true;

                const formSent = {
                    id: this.form.id,
                    company_office_ids: this.form.company_office_ids.map(x => x._id),
                    company_role_ids: this.form.company_role_ids.map(x => x._id)
                };

                const response = await this.updateCompanySettingOvertimeReportView(formSent);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Setting saved!');
                    await this.hideModal();
                    await this.setData();
                } else {
                    notificationDanger('Failed to save setting.');
                }
            } catch (err) {
                notificationDanger(err);
            }

        },
        fillForm(data) {
            try {
                this.formIsEdit = true;
                this.form.id = data._id;

                if (data.company_office_ids && data.company_office_ids.length > 0) {
                    for (const element of this.office_options[0].context) {                      
                        if (data.company_office_ids.find(x => x._id.toString() == element._id.toString())) {
                            this.form.company_office_ids.push(element);
                        }
                    }
                }

                if (data.company_role_ids && data.company_role_ids.length > 0) {
                    for (const element of this.role_options[0].context) {                      
                        if (data.company_role_ids.find(x => x._id.toString() == element._id.toString())) {
                            this.form.company_role_ids.push(element);
                        }
                    }
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        editForm(data) {
            this.fillForm(data);
            this.showModal();
        },
        resetForm() {
            this.formIsEdit = false;
            this.form = {
                company_office_ids: [],
                company_role_ids: [],
                id: null
            };            
        },
        async showModal() {
            await window.UIkit.modal('#formSetting').show();
        },
        async hideModal() {
            this.resetForm();            
            await window.UIkit.modal('#formSetting').hide();
        },
        backCompanyDetail() {
            this.$router.push(`/admin/mitra/company/detail/${this.$route.params.company_id}`);
        },
        rolesName(role){
            for (const opt of this.admin_options) {
                if (role == opt.value[0]) {
                    return opt.name;
                }
            }
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 50) {
                return data.substring(0,50)+'...';
            } else {
                return data;
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    }
};
</script>
