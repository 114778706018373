<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div class="uk-inline">
          <button
            class="uk-button uk-button-small-right uk-button-default"
            type="button"
            @click="backCompanyDetail"
          >
            <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
            Back
          </button>
        </div>
        <div class="uk-inline">
          <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
          <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'code')" type="text" placeholder="Search property type code">
        </div>
         <div class="uk-form-controls uk-width-small">
          <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
            <option :value=null>Select status</option>
            <option :value=1>Active</option>
            <option :value=0>Non Active</option>
          </select>
        </div>
        <div class="uk-inline">
          <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="showModal"
          v-if="consist(user_cred.roles, ['admin-bd','qwe123','superadmin-product'])">Create</button>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider">
          <thead>
            <tr>
              <th class="uk-text-center">Company Name</th>
              <th class="uk-text-center">Office Code</th>
              <th class="uk-text-center uk-width-1-4">Description</th>
              <th class="uk-text-center">Status</th>
              <th class="uk-text-center uk-width-1-4">Action</th>
            </tr>
          </thead>
          <tbody v-if="property_types.docs.length>0" class="uk-table-middle uk-text-center uk-table-striped">
            <tr v-for="(property_type, i) in property_types.docs" :key="i">
              <td>{{property_type.company.name}}</td>
              <td>{{property_type.code}}</td>
              <td>{{property_type.description}}</td>
              <td>
                <div class="label uk-margin-auto" :style="`background-color: ${property_type.status ? '#00cc00' : '#e63c3c'}`">
                  {{property_type.status ? 'Active' : 'Non Active'}}
                </div>
              </td>
              <td>
                <button
                  style="width:100px; cursor:pointer; margin-bottom:10px; height:30px; background:#333747"
                  class="uk-button-primary"
                  v-clipboard:copy="property_type._id"
                  v-clipboard:success="onCopy">
                  Copy Id
                </button><br/>
                <button
                  style="width:100px; cursor:pointer; height:30px"
                  class="uk-button-default uk-margin-right"
                  v-if="consist(user_cred.roles, ['admin-bd','qwe123','superadmin-product'])"
                  @click="editPropertyType(property_type)">
                  Edit
                </button>
                <button
                  v-if="isLoading"
                  style="width:100px; cursor:pointer; height:30px"
                  class="uk-button-danger" type="button" disabled>
                  <div uk-spinner></div>
                </button>
                <button
                  v-else-if="consist(user_cred.roles, ['admin-bd','qwe123','superadmin-product'])"
                  style="width:100px; cursor:pointer; height:30px"
                  class="uk-button-danger"
                  @click="showDeleteModal(property_type)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
          <empty-table v-else :colspan="6" />
        </table>
      </div>
      <pagination
        :total-data="property_types.totalDocs"
        :change-limit="changeLimit"
        :change-page="changePage"
      />
    </div>

    <div id="formPropertyTypeModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Office Type Form</h2>
        </div>
        <div class="uk-modal-body">

          <div class="uk-margin">
            <label class="uk-form-label">Company <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="propertyTypeForm.company_id"
              placeholder="Type to search company"
              label="name"
              name="company"
              track-by="name"
              v-validate="'required'"
              :options="company_options"
              :disabled="true"
              >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('company')">{{ errors.first('company') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Office Type Code <b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('property_type_code')}"
              name="property_type_code"
              type="text"
              v-model="propertyTypeForm.code"
              v-validate="'required'"
              placeholder="Code">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('property_type_code')">{{ errors.first('property_type_code') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Description <b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('description')}"
              name="description"
              type="text"
              v-model="propertyTypeForm.description"
              v-validate="'required'"
              placeholder="Description">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('description')">{{ errors.first('description') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Status <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="propertyTypeForm.status"
              placeholder="Select Status"
              label="name"
              name="status"
              track-by="name"
              v-validate="'required'"
              :options="status_options">
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('status')">{{ errors.first('status') }}</span>
          </div>

        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-primary" type="button" @click="savePropertyType">Save</button>
        </div>
      </div>
    </div>

    <div id="deletePropertyTypeModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideDeleteModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Delete Office Type?</h2>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin-small">
            <div>
              <h4>Company Name:</h4>
              <p>{{propertyTypeForm.company_id.name}}</p>
            </div>
            <div>
              <h4>Office Type Code:</h4>
              <p>{{propertyTypeForm.code}}</p>
            </div>
            <div>
              <h4>Description:</h4>
              <p>{{propertyTypeForm.description}}</p>
            </div>
            <div>
              <h4>Status:</h4>
              <p>{{propertyTypeForm.status.name}}</p>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-danger" type="button" @click="markPropertyTypeDeleted">Delete</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';

export default {
    data() {
        return {
            user_cred: getUserLogin(),
            meta: {
                limit: 100,
                page: 1,
                code: '',
                status: null,
                is_deleted: false,
                company_id: this.$route.params.company_id.toString(),
            },
            propertyTypeForm: {
                company_id: '',
                code: '',
                description: '',
                status: '',
            },
            formIsEdit: false,
            isLoading: false,
            status_options: [
                { name: 'Active', value: 1},
                { name: 'Non Active', value: 0}
            ],
            company_options: [],
            isFetching: false,
        };
    },
    components: {
        Pagination,
        EmptyTable
    },
    computed: {
        ...mapGetters({
            property_types: 'property_type/property_types',
            company: 'company/company',
        })
    },
    watch: {
        meta() {
            this.setPropertyTypeMeta();
        },
    },
    mounted() {
        this.setPropertyTypeMeta();
        this.setCompanyValues();
    },
    methods: {
        ...mapActions({
            getPropertyTypes: 'property_type/getPropertyTypes',
            createPropertyType: 'property_type/createPropertyType',
            updatePropertyType: 'property_type/updatePropertyType',
            deletePropertyType: 'property_type/deletePropertyType',
            getCompanyDetail: 'company/getCompanyDetail',
        }),
        setPropertyTypeMeta() {
            this.getPropertyTypes(this.meta);
        },
        async setCompanyValues() {
            this.isFetching = true;

            await this.getCompanyDetail(this.$route.params.company_id.toString());

            this.propertyTypeForm.company_id = {
                name: this.company.name,
                value: this.company._id
            };


            this.isFetching = false;
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
            this.getPropertyTypes(this.meta);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async savePropertyType() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                this.propertyTypeForm.company_id = this.propertyTypeForm.company_id.value;

                this.propertyTypeForm.status = this.propertyTypeForm.status.value;

                const response = this.formIsEdit
                    ? await this.updatePropertyType(this.propertyTypeForm)
                    : await this.createPropertyType(this.propertyTypeForm);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Office Type saved!');
                    this.hideModal();
                    this.setPropertyTypeMeta();
                } else {
                    notificationDanger('Failed to save office type.');
                }
            } catch (err) {
                notificationDanger(err);
            }

        },
        fillForm(property_type) {
            try {
                this.formIsEdit = true;
                this.propertyTypeForm.id = property_type._id;
                this.propertyTypeForm.code = property_type.code;
                this.propertyTypeForm.description = property_type.description;
                this.propertyTypeForm.status = this.status_options.find(
                    e => e.value === property_type.status
                );
                this.propertyTypeForm.company_id = {
                    name: property_type.company.name,
                    value: property_type.company._id
                };
            } catch (err) {
                notificationDanger(err);
            }
        },
        editPropertyType(property_type) {
            this.fillForm(property_type);
            this.showModal();
        },
        async markPropertyTypeDeleted() {
            try {
                this.isLoading = true;
                const response = await this.deletePropertyType(this.propertyTypeForm.id);
                this.isLoading = false;

                if (response && response.status === 'OK') {
                    notificationSuccess('Office type deleted!');
                    this.hideDeleteModal();
                    this.setPropertyTypeMeta();
                } else {
                    notificationDanger('Failed to delete office type.');
                }

            } catch (err) {
                notificationDanger(err);
            }
        },
        resetForm() {
            this.formIsEdit = false;
            this.propertyTypeForm = {
                company_id: this.propertyTypeForm.company_id,
                code: '',
                description: '',
                status: '',
            };
            this.$validator.reset();
        },
        async showDeleteModal(property_type) {
            this.fillForm(property_type);
            await window.UIkit.modal('#deletePropertyTypeModal').show();
        },
        async hideDeleteModal() {
            await window.UIkit.modal('#deletePropertyTypeModal').hide();
            this.resetForm();
        },
        async showModal() {
            await window.UIkit.modal('#formPropertyTypeModal').show();
        },
        async hideModal() {
            await window.UIkit.modal('#formPropertyTypeModal').hide();
            this.resetForm();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        backCompanyDetail() {
            this.$router.push(`/admin/mitra/company/detail/${this.$route.params.company_id}`);
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    }
};
</script>
